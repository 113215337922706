<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <Logo/>
      </v-col>
      <v-col class="mt-6" cols="12">
        <h2 class="d-flex justify-center align-center mb-3">THANK YOU FOR SIGNING UP</h2>
        <p class="d-flex justify-center align-center mb-1">
          You have received an email with a link to finish the sign up process. If you would like to return to website please
        </p>
        <router-link :to="{path: 'password', query: {email: this.$route.query.email}}" class="d-flex justify-center align-center">click here</router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Logo from "@/components/common/Logo.vue"

export default {
  components: {
    Logo
  },
  mounted() {
    // this.waitThreeSec();
  },
  methods: {
    waitThreeSec() {
      setTimeout(() => this.$router.replace("/signin"), 3000);
    },
  },
};
</script>
